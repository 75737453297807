import React from 'react';
import ForgetPasswordForm from '../ForgetPasswordForm';
import {useTranslation} from "react-i18next";

const ForgetPassword = () => {
  const { t } = useTranslation('translations');

  return (
    <div className="text-center">
      <ForgetPasswordForm />
    </div>
  );
};

export default ForgetPassword;
