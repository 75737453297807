import React, { Fragment } from 'react';
import LoginForm from '../LoginForm';
import withRedirect from "../../../hoc/withRedirect";
import {useHistory} from "react-router-dom";

const Login = ({ params }) => {
    const history = useHistory();

    function redirectHome() {
        history.push('/');
    }

    return <div>
        <Fragment>
            <LoginForm params={params} redirectHome={redirectHome}/>
        </Fragment>
    </div>

};

export default withRedirect(Login);
