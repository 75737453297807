import React from 'react';
import Avatar from '../common/Avatar';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignOutAlt, faWrench} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const ProfileDropdown = () => {
  const { t } = useTranslation('translations');

  const image = localStorage.getItem('user_image');
  return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav className="pr-0">
          <Avatar src={image} />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-card">
          <div className="bg-white rounded-soft py-2">
            <DropdownItem className="font-weight-bold" href="/pages/settings">
              <FontAwesomeIcon icon={faWrench} className="mr-1" />
              <span>{t('common.settings')}</span>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem className="font-weight-bold text-warning" to="/authentication/basic/logout" href="/logout">
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />
                {t('common.logout')}
            </DropdownItem>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>

  );
};

export default ProfileDropdown;
