import { useEffect, useState } from 'react';
import axios from "axios";
import {APIURL} from "../config";

const useJobsStatisticFetch = (userData) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {

          const fetchData = async () => {
              const token = localStorage.getItem('token');

              try {
                  const response = await axios.get(APIURL + '/partner/stats/general',
                      {
                          headers: {
                              'Authorization': `Bearer ${token}`
                          }}
                          );

                  setData(response.data)
                  setLoading(false);
              } catch (error) {
                  setLoading(false);
              }
          };

          fetchData()

  }, [userData]);

    return { loading, setLoading, data, setData };
};

export default useJobsStatisticFetch;
