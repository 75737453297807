import React, { useEffect } from "react";
import * as querystring from "querystring";
import {toast} from "react-toastify";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Loader from '../common/Loader';
import {APIURL} from "../../config";

const StripeCallbackComponent = ({ location: { search }}) => {
  const history = useHistory();

  const performAccountBindingAndRedirect = async (code) => {
    try {
      const response = await axios.put(`${APIURL}/partner/stripe`, {
        code,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      if (response.status === 200) {
        toast.success('Successfully connected account with Stripe!');
      }
    } catch (error) {
      toast.error(error.response.data.details);
    } finally {
      history.push('/');
    }
  }

  useEffect(() => {
    const { code, state } = querystring.parse(search.replace('?', ''));
    if (code) {
      performAccountBindingAndRedirect(code);
    }
  }, []);

  return (
    <div className="position-fixed h-100 w-100 d-flex align-items-center justify-content-center"><Loader /></div>
  );
};

export default StripeCallbackComponent;
