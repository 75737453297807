import React from 'react';
import PasswordResetForm from '../PasswordResetForm';
import { useTranslation } from "react-i18next";

const PasswordReset = () => {
  const { t } = useTranslation('translations');

  return (
    <div className="text-center">
      <h5 className="mb-3">{t('auth.resetPassword.title')}</h5>
      <span>{t('auth.resetPassword.description')}</span>
      <PasswordResetForm/>
    </div>
  );
};

export default PasswordReset;
