import React, {useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { LoginContext } from "./context/Context";
import i18next from "i18next";
import { getLanguage } from "./helpers/utils";

const App = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const value = {email, setEmail, password, setPassword};

    i18next.changeLanguage(getLanguage())

    return (
    <Router basename={process.env.PUBLIC_URL}>
        <LoginContext.Provider value={value}>
            <Layout />
        </LoginContext.Provider>
    </Router>
  );
};

export default App;
