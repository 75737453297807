import React from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import LoginService from '../../../services/login';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { APPURL } from '../../../config';
import i18n from 'i18next';

const AppleSignInButton = ({ signUp, requestCompleted }) => {
  const { t } = useTranslation('translations');
  const history = useHistory();

  const validateAppleToken = async tokenData => {
    const {
      authorization: { id_token },
    } = tokenData;

    const {
      data: { result },
    } = await LoginService.checkAppleToken(id_token);

    const { data } = await LoginService.loginWithApple(id_token);

    // Set language for the portal based on user settings
    const {
      userDetails: { language },
    } = data;
    if (language) await i18n.changeLanguage(language);

    if (signUp) {
      // Account exists and phone verification done
      return requestCompleted({ ...data, identityToken: id_token });
    } else {
      // Account exists and phone verification done
      if (result) {
        if (data.partner) {
          return history.push('/');
        } else {
          toast.warn(t('auth.login.partnerMustBeCreatedFirst'));
          return history.push('/onboarding');
        }
      } else {
        toast.warn(t('auth.login.createAccountFirst'));
        return history.push('/onboarding');
      }
    }
  };

  return (
    <AppleSignin
      /** Auth options passed to AppleID.auth.init() */
      authOptions={{
        /** Client ID - eg: 'com.example.com' */
        clientId: 'com.easypanda.partner',
        /** Requested scopes, seperated by spaces - eg: 'email name' */
        scope: 'email name',
        // Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple
        // docs is that you should call auth from a page that is listed as a redirectURI, localhost fails
        redirectURI: `${APPURL}/auth/login/apple`,
        /** State string that is returned with the apple response */
        state: 'state',
        /** Nonce */
        nonce: 'nonce',
        /** Uses popup auth instead of redirection */
        usePopup: true,
      }} // REQUIRED
      /** General props */
      uiType="dark"
      /** className */
      className="apple-auth-btn"
      /** Removes default style tag */
      noDefaultStyle={false}
      /** Allows to change the button's children, eg: for changing the button text */
      buttonExtraChildren="Continue with Apple"
      /** Extra controlling props */
      /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
      onSuccess={validateAppleToken} // default = undefined
      /** Called upon signin error */
      onError={error => toast.error(t('auth.login.appleIdError'))} // default = undefined
      /** Skips loading the apple script if true */
      skipScript={false} // default = undefined
      /** Apple image props */
      /** render function - called with all props - can be used to fully customize the UI by rendering your own component  */
      render={btnProps => {
        return (
          <Button {...btnProps} size="sm" color="apple" outline={true} className="w-100">
            <FontAwesomeIcon icon={faApple} className="mr-2" />
            <span>{t('auth.login.signWithApple')}</span>
          </Button>
        );
      }}
    />
  );
};

AppleSignInButton.defaultProps = {
  signUp: false,
  requestCompleted: () => {},
};

AppleSignInButton.propTypes = {
  signUp: PropTypes.bool,
  requestCompleted: PropTypes.func,
};

export default AppleSignInButton;
