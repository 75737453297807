import React, { useState, useEffect, useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import LoginService from '../../services/login';
import { LoginContext } from '../../context/Context';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppleSignInButton from './thirdParty/AppleSignInButton';
import FacebookSignInButton from './thirdParty/FacebookSignInButton';
import i18n from 'i18next';

const LoginForm = ({ setRedirect, hasLabel, redirectHome }) => {
  const { t } = useTranslation('translations');
  const [isDisabled, setIsDisabled] = useState(true);
  const { email, setEmail, password, setPassword } = useContext(LoginContext);

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [email, password]);

  const handleSubmit = async e => {
    e.preventDefault();

    const authHandler = async () => {
      try {
        const userData = await LoginService.login({ email, password });
        const {
          data: {
            userDetails: { name, language },
          },
        } = userData;

        if (language) {
          await i18n.changeLanguage(language);
        }

        if (userData.data.accessToken) {
          toast.success(t('auth.login.welcomeToast', { name }));
          redirectHome();
        }
      } catch (err) {
        toast.error(t('auth.login.failedToast'));
      }
    };

    await authHandler(setRedirect);
  };

  return (
    <Fragment>
      <div className="text-left justify-content-between row align-items-center mb-3">
        <div className="col-auto">
          <h5 className="mb-0">{t('auth.login.loginTitle')}</h5>
        </div>
        <div className="col-auto f-dlex align-items-center">
          <Link to="/onboarding">{t('auth.login.createAccountLink')}</Link>
        </div>
      </div>

      <Form onSubmit={handleSubmit} autoComplete="on">
        <FormGroup>
          {hasLabel && <Label>{t('auth.login.emailLabel')}</Label>}
          <Input
            placeholder={!hasLabel ? t('auth.login.emailPlaceholder') : ''}
            autoComplete="off"
            onChange={({ target }) => setEmail(target.value)}
            type="email"
          />
        </FormGroup>
        <FormGroup>
          {hasLabel && <Label>{t('auth.login.passwordLabel')}</Label>}
          <Input
            placeholder={!hasLabel ? t('auth.login.passwordPlaceholder') : ''}
            value={password}
            autoComplete="off"
            onChange={({ target }) => setPassword(target.value)}
            type="password"
          />
        </FormGroup>

        <div className="row d-flex justify-content-end">
          <div className="col-auto">
            <Link className="fs--1" to="/auth/password-recovery">
              {t('auth.login.passwordRecoveryLink')}
            </Link>
          </div>
        </div>

        <FormGroup className="mb-0">
          <Button color="primary" block className="mt-3" disabled={isDisabled}>
            {t('auth.login.loginButton')}
          </Button>
        </FormGroup>
      </Form>

      <div className="w-100 position-relative text-center mt-4 mb-4">
        <hr className="text-300" />
        <div className="position-absolute absolute-centered t-0 px-3 bg-white text-sans-serif fs--1 text-500 text-nowrap">
          {t('auth.login.orUse3rdParty')}
        </div>
      </div>

      <Row>
        <Col sm={6}>
          <AppleSignInButton />
        </Col>
        <Col sm={6}>
          <FacebookSignInButton />
        </Col>
      </Row>
    </Fragment>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false,
};

export default withRedirect(LoginForm);
