import axios from 'axios';
import { APIURL } from '../config';
import { toast } from 'react-toastify';

export default class UserService {
  static updateUserLanguage = async newLanguage => {
    const token = localStorage.getItem('token');

    return await axios.put(
      `${APIURL}/user`,
      {
        user: {
          language: newLanguage.toLowerCase(),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  };

  static fetchCurrentUser = async () => {
    const token = localStorage.getItem('token');
    let response;

    try {
      response = await axios.get(`${APIURL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      return false;
    }

    return response;
  };

  static async unlinkUser(userID) {
    const token = localStorage.getItem('token');
    let success = false;

    await axios
      .post(
        APIURL + '/partner-functions/unlink-user',
        {
          userId: userID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Requested-With': 'XMLHttpRequest',
          },
        },
      )
      .then(res => {
        success = true;
        toast.success('successfully unlinked user');
      })
      .catch(res2 => {
        toast.error('failed to unlink user');
      });

    return success;
  }
}
