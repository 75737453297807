import { useEffect, useState } from 'react';
import axios from "axios";
import {APIURL} from "../config";

const usePartnerFetch = (prevLoad) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(APIURL + '/partner',
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data &&  response.data.data && response.data.data.bookings === true ) {
                localStorage.setItem('bookings', 1)
            } else {
                localStorage.removeItem('bookings');
            }

            setData(response.data)
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData()
    }, [prevLoad]);

    return { loading, setLoading, data, setData, fetchData };
};

export default usePartnerFetch;
