import { createAction } from 'redux-actions';
import jobsService from "../hooks/jobs";

export const requestJobs = createAction('REQUEST_JOBS_VALUES');
export const jobsRequestSuccess = createAction('JOBS_REQUEST_SUCCESS');
export const jobsRequestFailed = createAction('JOBS_REQUEST_FAILED');

export function getJobsValues(userData) {
    return async (dispatch) => {
        // dispatch(requestJobs(userData));

        try {
            const jobs = await jobsService.getAll(userData);
            dispatch(jobsRequestSuccess(jobs));
        } catch (e) {
            dispatch(jobsRequestFailed(e));
        }
    }
}
