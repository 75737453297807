import React, { useContext } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';
import AppContext from '../../context/Context';
import Logo from './Logo';
import ProfileDropdown from './ProfileDropdown';
import LanguageSwitcher from '../shared/LanguageSwitcher';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);

  return (
    <Navbar
      light
      className="navbar-glass d-flex justify-content-between fs--1 font-weight-semi-bold row navbar-top sticky-kit"
      expand
    >
      <div className="d-flex justify-content-start align-items-center">
        <NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />
        <Logo at="navbar-top" width={40} id="topLogo" />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <LanguageSwitcher className="mr-3" />
        <Collapse navbar style={{ flexGrow: 0 }}>
          <Nav navbar className="align-items-center ml-auto">
            <ProfileDropdown />
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
};

export default NavbarTop;
