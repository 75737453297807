import React, { useContext } from 'react';
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import corner2 from '../../assets/img/illustrations/corner-2.png';
import Background from "../common/Background";
import PartnerContext from "../../context/PartnerContext";

const SetUpPricing = () => {
  const { t } = useTranslation('translations');
  const { partner } = useContext(PartnerContext);
  const history = useHistory();

  return partner && partner.stripeAccountId && partner.flows.length === 0 ? (
    <div className="card-deck">
      <div className="mb-3 card">
        <Background image={corner2} className="bg-card" />
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <h5 className="fs-0">{t('dashboard.providePricing.subtitle')}</h5>
              <h3 className="mb-0">{t('dashboard.providePricing.title')}</h3>
              <p className="mt-2">{t('dashboard.providePricing.content')}</p>
              <Button className="col-12 col-sm-6 col-md-4" onClick={() => history.push('/pricing')} color="primary" block={true}>
                { t('dashboard.providePricing.button') }
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default SetUpPricing;
