import React, {useEffect} from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import Logo from '../components/navbar/Logo';
import { Redirect, Route, Switch } from 'react-router-dom';
import Section from '../components/common/Section';
import Login from '../components/auth/basic/Login';
import ForgetPassword from '../components/auth/basic/ForgetPassword';
import PasswordReset from '../components/auth/basic/PasswordReset';
import LanguageSwitcher from '../components/shared/LanguageSwitcher';

function AuthBasicLayout() {
  // Clear API partner flag on login page
  useEffect(() => {
    localStorage.removeItem('api_only');
  }, []);

  return (
    <Section className="py-0">
      <div className="py-3 d-flex align-items-center justify-content-end">
        <LanguageSwitcher />
      </div>
      <Row className="flex-center min-vh-100 pb-6">
        <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <Logo />
          <Card>
            <CardBody className="fs--1 font-weight-normal p-5">
              <Switch>
                <Route path="/auth/password-recovery" component={ForgetPassword} />
                <Route path="/auth/password-reset" component={PasswordReset} />
                <Route path="/auth/login" component={Login} />
                <Redirect to="/auth/login" />
              </Switch>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  );
}

export default AuthBasicLayout;
