import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from "react-router-dom";
import { PartnerProvider } from "../context/PartnerContext";
import StripeCallbackComponent from "../components/callbacks/StripeCallbackComponents";

const CallbackLayout = ({ location }) => {
  return (
    <PartnerProvider>
      <Route path='/callback/stripe'>
        <StripeCallbackComponent location={location} />
      </Route>
    </PartnerProvider>
  );
};

CallbackLayout.propTypes = { location: PropTypes.object.isRequired };

export default CallbackLayout;
