import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import withRedirect from '../../hoc/withRedirect';

const PasswordResetForm = () => {
  const { t } = useTranslation('translations');
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t('auth.resetPassword.fieldRequired'))
      .min(8, t('auth.resetPassword.minimumPasswordLength', { count: 8 })),
    newPasswordConfirmation: Yup.string()
      .required(t('auth.resetPassword.fieldRequired'))
      .oneOf([Yup.ref('newPassword')], t('auth.resetPassword.doNotMatch')),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  /**
   * Verify that access to this component happens with verificationCode and phoneNumber in state,
   * otherwise direct user to obtain verification code.
   */
  useEffect(() => {
    const passwordResetToken = new URLSearchParams(location.search).get('passwordResetToken');
    if (!passwordResetToken) {
      toast.error(t('auth.resetPassword.codeNotProvided'));
      history.push({ pathname: '/auth/password-recovery' });
    }
  }, [location]);

  /**
   * Perform password update to new value, upon error, direct to obtain verification code,
   * on success direct user to login screen.
   * @param newPassword[string]
   * @returns {Promise<void>}
   */
  const setPassword = async ({ newPassword }) => {
    setIsDisabled(true);
    const passwordResetToken = new URLSearchParams(location.search).get('passwordResetToken');

    try {
      const response = await AuthService.changePassword(passwordResetToken, newPassword);

      if (response.status === 200) {
        toast.success(t('auth.resetPassword.newPasswordSetToast'));
        return history.push({ pathname: '/' });
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      toast.error(t('auth.resetPassword.wrongTokenToast'));
      return history.push({ pathname: '/auth/password-recovery' });
    }
  };

  return (
    <Form className="mt-3" onSubmit={handleSubmit(setPassword)}>
      <Row>
        <Col>
          <FormGroup>
            <input
              name="newPassword"
              id="newPassword"
              defaultValue={null}
              type="password"
              disabled={isDisabled}
              placeholder={t('auth.resetPassword.passwordPlaceholder')}
              className={classNames({ 'border-danger': errors && errors.newPassword?.message, 'form-control': true })}
              {...register('newPassword')}
            />
            {errors.newPassword && <span className="text-danger">{errors.newPassword.message}</span>}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup>
            <input
              name="newPasswordConfirmation"
              id="newPasswordConfirmation"
              defaultValue={null}
              type="password"
              disabled={isDisabled}
              placeholder={t('auth.resetPassword.passwordConfirmationPlaceholder')}
              className={classNames({
                'border-danger': errors && errors.newPasswordConfirmation?.message,
                'form-control': true,
              })}
              {...register('newPasswordConfirmation')}
            />
            {errors.newPasswordConfirmation && (
              <span className="text-danger">{errors.newPasswordConfirmation.message}</span>
            )}
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Button color="primary" block type="submit" disabled={isDisabled}>
          {t('auth.resetPassword.setPasswordCTA')}
        </Button>
      </FormGroup>
    </Form>
  );
};

PasswordResetForm.propTypes = {};

export default withRedirect(PasswordResetForm);
