import React from 'react';
import PropTypes from 'prop-types';
import { Col, CardHeader, Row } from 'reactstrap';
import classNames from 'classnames';
import Avatar from "./Avatar";
import {Link} from "react-router-dom";

const Title = ({ titleTag: TitleTag, className, breakPoint, children }) => (
  <TitleTag
    className={classNames(
      {
        'mb-0': !breakPoint,
        [`mb-${breakPoint}-0`]: !!breakPoint
      },
      className
    )}
  >
    {children}
  </TitleTag>
);

Title.propsType = {
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.node
};

Title.defaultProps = { titleTag: 'h5' };

const FalconCardHeader = ({ title, secondTitle, secondTitleUrl, light, titleTag, titleClass, className, breakPoint, imageUrl, children }) => (
  <CardHeader className={classNames({ 'bg-light': light }, className)}>
    {children ? (
      <Row className="align-items-center">
        <Col className="d-flex justify-content-start align-items-center">
          {imageUrl ? (
            <Avatar
                src={imageUrl}
                rounded="soft"
                size="3xl"
                className="mr-3"
            />
          ) : null}
          <div>
            {secondTitle && secondTitleUrl ? (
              <Link to={secondTitleUrl}>
                {secondTitle}
              </Link>
            ) : null}
            {secondTitle && !secondTitleUrl ? (
              <p className="mb-0">
                  {secondTitle}
              </p>
            ) : ''}
            <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
              {title}
            </Title>
          </div>
        </Col>
        <Col
          {...{ [breakPoint ? breakPoint : 'xs']: 'auto' }}
          className={`text${breakPoint ? `-${breakPoint}` : ''}-right`}
        >
          {children}
        </Col>
      </Row>
    ) : (
      <Title breakPoint={breakPoint} titleTag={titleTag} className={titleClass}>
        {title}
      </Title>
    )}
  </CardHeader>
);

FalconCardHeader.propTypes = {
  title: PropTypes.node.isRequired,
  light: PropTypes.bool,
  breakPoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl']),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

FalconCardHeader.defaultProps = { light: true };

export default FalconCardHeader;
