import React, { useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import i18n from 'i18next';
import UserService from '../../services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LanguageSwitcher = () => {
  const languages = [
    { key: 'en', label: 'English' },
    { key: 'lv', label: 'Latviešu' },
    { key: 'ee', label: 'Eesti keel' },
    { key: 'lt', label: 'Lietuvių' },
  ];

  const [current, setCurrent] = useState(languages[0]);

  const changeLanguage = async language => {
    await i18n.changeLanguage(language);
    localStorage.setItem('locale', language);
    const _currentLang = languages.find(langObj => langObj.key === i18n.language);
    setCurrent(_currentLang);

    const token = localStorage.getItem('token');
    if (token) {
      await UserService.updateUserLanguage(_currentLang.key);
    }
  };

  useEffect(() => {
    if (i18n.language !== current.key) {
      changeLanguage(i18n.language);
    }
  }, [i18n.language]);

  /**
   * Load user and set his desired language as the main interface
   * @returns {Promise<void>}
   */
  const loadUserPreference = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      const {
        data: { user },
        status,
      } = await UserService.fetchCurrentUser();

      if (status === 200 && user?.language) {
        await changeLanguage(user.language);
      }
    }
  };

  /**
   * Upon first load fetch user and his settings here, since no User provider has
   * been created for this project.
   * TODO: once userProvider is implemented, replace this logic
   */
  useEffect(() => {
    loadUserPreference();
  }, []);

  return (
    <UncontrolledDropdown className="language-switcher">
      <DropdownToggle caret color="default">
        {current.label}
      </DropdownToggle>
      <DropdownMenu className="py-0">
        {languages.map(langObj => {
          return (
            <DropdownItem
              key={langObj.key}
              className="px-3 p-2 d-flex align-items-center justify-content-between"
              onClick={() => (langObj.key !== i18n.language ? changeLanguage(langObj.key) : null)}
            >
              {langObj.label}
              {langObj.key === i18n.language ? <FontAwesomeIcon icon="check" className="ml-2" /> : null}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default LanguageSwitcher;
