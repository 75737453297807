import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Input, Form } from 'reactstrap';
import CountUp from 'react-countup';
import CardSummary from './CardSummary';
import FalconCardHeader from '../common/FalconCardHeader';
import loadable from '@loadable/component';
import useJobsFetch from '../../hooks/useJobsFetch';
import { LoginContext } from '../../context/Context';
import useJobsStatisticFetch from '../../hooks/useJobsStatisticFetch';
import moment from 'moment';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import StripeConnection from './StripeConnection';
import SetUpPricing from './SetUpPricing';
import { filterJobs } from '../../helpers/filterHelper';
import {useHistory} from "react-router-dom";

const PurchasesTable = loadable(() => import('./PurchasesTable'));

function Dashboard() {
  const { userData } = useContext(LoginContext);
  const history = useHistory();
  const { t } = useTranslation('translations');
  const { data, loading } = useJobsFetch(userData);
  const jobsStatistic = useJobsStatisticFetch(userData);
  const [searchField, setSearchField] = useState('');
  const [filteredJobsTemp, setFilteredJobsTemp] = useState([]);

  useEffect(() => {
    const filtered = filterJobs(data.jobs, searchField);
    setFilteredJobsTemp(filtered);
  }, [data]);

  useEffect(() => {
    const apiOnly = localStorage.getItem('api_only') === '1';
    if (apiOnly) history.push('/api-bookings');
  }, []);

  const nextMonday = () => {
    return moment(new Date())
      .startOf('isoWeek')
      .add(1, 'week')
      .format('MMMM DD');
  };

  const payoutSum = jobsStatistic => {
    let sum = 0;
    if (jobsStatistic.loading === false && jobsStatistic.data && jobsStatistic.data.payout) {
      sum = jobsStatistic.data.payout.toFixed(2);
    }

    return sum;
  };

  return !loading ? (
    <Fragment>
      <StripeConnection />
      <SetUpPricing />

      <div className="card-deck">
        <CardSummary title={t('task.available_tasks')} color="warning">
          {jobsStatistic.loading === true ? (
            0
          ) : (
            <CountUp end={jobsStatistic.data.availableTasks} duration={3} decimal="." />
          )}
        </CardSummary>
        <CardSummary title={t('task.completed_tasks')} color="info">
          {jobsStatistic.loading === true ? (
            0
          ) : (
            <CountUp end={jobsStatistic.data.completedTasks} duration={3} decimal="." />
          )}
        </CardSummary>
        <CardSummary title={t('task.revenue')} color="success">
          {jobsStatistic.loading === true ? (
            0
          ) : (
            <CountUp end={jobsStatistic.data.revenue} duration={1} decimals={2} decimal="." />
          )}{' '}
          EUR
        </CardSummary>
      </div>

      <Card className="bg-light mb-3">
        <CardBody className="p-3">
          <p className="fs--1 mb-0">
            <FontAwesomeIcon icon={faMoneyBill} size="lg" color="green" />{' '}
            {t('settings.your_next_payout', {
              weekday: nextMonday(),
              jobStatistic: payoutSum(jobsStatistic),
            })}
          </p>
        </CardBody>
      </Card>

      <Card className="mb-3">
        <FalconCardHeader title={t('task.available_tasks')} light={false}>
          <Form inline>
            <Input bsSize="sm" placeholder="Search..." onChange={el => setSearchField(el.target.value)} />
          </Form>
        </FalconCardHeader>
        <CardBody className="p-0">
          <PurchasesTable jobs={filteredJobsTemp} />
        </CardBody>
      </Card>
    </Fragment>
  ) : null;
}

export default Dashboard;
