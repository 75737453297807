import { useEffect, useState } from 'react';
import axios from 'axios';
import { APIURL } from '../config';
import i18n from 'i18next';

const useJobsFetch = userData => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const token = localStorage.getItem('token');
    const locale = i18n.language;

    try {
      const response = await axios.get(
        `${APIURL}/partner/jobs?query[status]=created,accepted,costEstimated,paid&perPage=1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            locale,
          },
          timeout: 2000,
        },
      );
      setData(response.data);
      setLoading(false);
    } catch (error) {
      localStorage.removeItem('token');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userData]);

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  return { loading, setLoading, data, setData };
};

export default useJobsFetch;
