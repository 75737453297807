import axios from 'axios';
import { APIURL, CDN_DOMAIN } from '../config';
import defaultUserPicture from '../assets/img/generic/default-user-picture.png'

export default class LoginService {
  /**
   * Perform regular login with email and password and create user session is successful
   * @param loginInfo
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async login(loginInfo) {
    const data = { email: loginInfo.email, password: loginInfo.password };
    const response = await axios.post(`${APIURL}/auth/login`, data);
    const {
      accessToken,
      partner: { displayImage, _id },
    } = response.data;

    localStorage.clear();
    localStorage.setItem('token', accessToken);
    localStorage.setItem('user_image', displayImage ? CDN_DOMAIN + '/' + displayImage.assetKey : defaultUserPicture);
    localStorage.setItem('partner_id', _id);
    localStorage.setItem('api_only', response.data.integrations.length > 0 ? '1' : '0');

    return response;
  }

  /**
   * Perform login with Apple ID and set auth tokens
   * @param token
   * @param options `skipSession` to prevent creation of session
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async loginWithApple(token, options) {
    const response = await axios.post(`${APIURL}/auth/login`, {
      identityToken: token,
    });

    try {
      const { accessToken } = response.data;

      localStorage.clear();
      localStorage.setItem('token', accessToken);

      const {
        partner: { displayImage, _id },
      } = response.data;
      localStorage.setItem('user_image', displayImage ? CDN_DOMAIN + '/' + displayImage.assetKey : null);
      localStorage.setItem('partner_id', _id);
      localStorage.setItem('api_only', response.data.integrations.length > 0 ? '1' : '0');
    } catch (err) {
      console.log(err);
    } finally {
      return response;
    }
  }

  /**
   * Perform login with Facebook and set auth tokens
   * @param accessToken
   * @param options `skipSession` to prevent creation of session
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async loginWithFacebook(accessToken, options = {}) {
    const response = await axios.post(`${APIURL}/auth/login-facebook`, { facebookToken: accessToken });

    try {
      const { accessToken } = response.data;

      localStorage.clear();
      localStorage.setItem('token', accessToken);

      const {
        partner: { displayImage, _id },
      } = response.data;
      localStorage.setItem('user_image', displayImage ? CDN_DOMAIN + '/' + displayImage.assetKey : null);
      localStorage.setItem('partner_id', _id);
      localStorage.setItem('api_only', response.data.integrations.length > 0 ? '1' : '0');
    } catch (err) {
      console.log(err);
    } finally {
      return response;
    }
  }

  static async signupWithFacebook(accessToken, email) {
    const response = await axios.post(`${APIURL}/auth/sign-up`, {
      _fbLogin: true,
      email: email,
      facebookToken: accessToken,
    });

    try {
      const { accessToken } = response.data;

      localStorage.clear();
      localStorage.setItem('token', accessToken);

      const {
        partner: { displayImage, _id },
      } = response.data;
      localStorage.setItem('user_image', displayImage ? CDN_DOMAIN + '/' + displayImage.assetKey : null);
      localStorage.setItem('partner_id', _id);
    } catch (err) {
      console.log(err);
    } finally {
      return response;
    }
  }

  /**
   * Check that user exists by their identity token
   * @param token
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async checkAppleToken(token) {
    return await axios.post(`${APIURL}/auth/check-user`, {
      key: 'IS-EXISTING-EMAIL',
      identityToken: token,
    });
  }

  static async checkFacebookToken(accessToken, email) {
    return await axios.post(`${APIURL}/auth/check-user`, {
      key: 'IS-EXISTING-FACEBOOK',
      val: accessToken,
      email: email,
    });
  }
}
