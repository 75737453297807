import React, { useContext } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import { navbarBreakPoint } from '../../config';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import AppContext from '../../context/Context';
import { useTranslation } from 'react-i18next';
import {
  faBook,
  faCreditCard,
  faTag,
  faHome,
  faStarHalfAlt,
  faMoneyCheckAlt,
  faPhotoVideo,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';

const isBurgerMenuShown = () => {
  const burgerMenu = document.getElementById('burgerMenu');
  return !(getComputedStyle(burgerMenu).display === 'none');
};

const NavbarVertical = () => {
  const { t } = useTranslation('translations');
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);
  const apiOnly = localStorage.getItem('api_only') === '1';

  const routes = apiOnly ? [
    {
      name: t('navigation.api-bookings'),
      to: '/api-bookings',
      icon: 'list-ul',
    },
    {
      name: t('navigation.settings'),
      to: '/pages/settings',
      icon: faWrench,
    },
  ] : [
    {
      name: t('navigation.home'),
      to: '/',
      exact: true,
      icon: faHome,
    },
    {
      name: t('navigation.bookings'),
      to: '/bookings',
      icon: 'list-ul',
    },
    {
      name: t('navigation.pricing'),
      to: '/pricing',
      icon: faTag,
    },
    {
      name: t('navigation.portfolio'),
      to: '/portfolio',
      exact: false,
      icon: faPhotoVideo,
    },
    {
      name: t('navigation.linked_accounts'),
      to: '/linked-accounts',
      icon: 'users',
    },
    {
      name: t('navigation.reviews'),
      to: '/reviews',
      icon: faStarHalfAlt,
    },
    {
      name: t('navigation.billings'),
      to: '/billings',
      icon: faCreditCard,
    },
    {
      name: t('navigation.settings'),
      to: '/pages/settings',
      icon: faWrench,
    },
  ];

  const handleNavbarVerticalCollapse = () => isBurgerMenuShown() && setShowBurgerMenu(!showBurgerMenu);

  return (
    <Navbar expand={navbarBreakPoint} className="navbar-vertical navbar-glass" light>
      <Logo at="navbar-vertical" width={100} />
      <Collapse navbar isOpen={showBurgerMenu}>
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={routes} handleNavbarVerticalCollapse={handleNavbarVerticalCollapse} />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
