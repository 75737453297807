export const filterJobs = (jobs, searchField) => {
  return (jobs || []).filter((job) => {
    return returnFilterMatches(job, searchField);
  });
};


/**
 * Perform checking for object fields for specific string and return `true` if there is a match.
 * @param job object
 * @param searchString string
 * @returns {boolean}
 */
export const returnFilterMatches = (job, searchString) => {
  let titleMatch = true;
  if (job.flowInfo?.flow) {
    titleMatch = job.flowInfo?.flow.title.match(new RegExp(searchString, "i"));
  }

  const categoryMatch = job.jobCategory && job.jobCategory.categoryName.match(new RegExp(searchString, "i"));

  let firstNameMatch = true;
  let lastNameMatch = true;
  if (job.postedBy && job.postedBy.firstName) {
    firstNameMatch = job.postedBy && job.postedBy.firstName.match(new RegExp(searchString, "i"));
    lastNameMatch = job.postedBy && job.postedBy.lastName.match(new RegExp(searchString, "i"));
  }

  if (job.postedBy && job.postedBy.name) {
    firstNameMatch = job.postedBy && job.postedBy.name.match(new RegExp(searchString, "i"));
  }

  return (titleMatch || categoryMatch || firstNameMatch || lastNameMatch);
}
