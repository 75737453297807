import axios from 'axios';
import { APIURL, CDN_DOMAIN } from '../config';
import defaultUserPicture from '../assets/img/generic/default-user-picture.png';

export default class AuthService {
  /**
   * Requests password reset phone verification code
   * @param {string} email
   * @returns {Promise<any>}
   */
  static async requestPasswordReset(email) {
    const data = { email: email };
    let response;

    try {
      response = await axios.post(`${APIURL}/auth/recovery`, data);
    } catch (err) {
      response = err.response;
    }

    return response;
  }

  /**
   * Finalizes password change by providing new password and phone verification code
   * @param {string} passwordResetToken
   * @param {string} newPassword
   * @returns {Promise<any>}
   */
  static async changePassword(passwordResetToken, newPassword) {
    const data = { passwordResetToken, newPassword };
    const response = await axios.post(`${APIURL}/auth/change-password`, data);
    const {
      accessToken,
      partner: { displayImage, _id },
    } = response.data;

    localStorage.clear();
    localStorage.setItem('token', accessToken);
    localStorage.setItem('user_image', displayImage?.assetKey ? CDN_DOMAIN + '/' + displayImage.assetKey : defaultUserPicture);
    localStorage.setItem('partner_id', _id);

    return response;
  }
}
