import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import { CategoriesProvider } from '../context/CategoriesContext';
import { PartnerProvider } from '../context/PartnerContext';
import UserService from '../services/userService';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));

const DashboardLayout = ({ location }) => {
  const [isReady, setIsReady] = useState(false);
  const { isFluid } = useContext(AppContext);
  const history = useHistory();

  /**
   * Verify that user has required attributes, like link with Partner to proceed with dashboard
   * otherwise navigates user to login screen.
   * @returns {Promise<void>}
   */
  const checkPartnerUser = async () => {
    const response = await UserService.fetchCurrentUser();
    let abort = false;

    if (!response) abort = true;

    if (!abort) {
      const {
        data: { user },
      } = response;

      if (user.partnerID) {
        setIsReady(true);
      } else {
        abort = true;
      }
    }

    if (abort) {
      localStorage.clear();
      history.push('/auth/login');
    }
  };

  useEffect(() => {
    checkPartnerUser();
  }, []);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return isReady ? (
    <CategoriesProvider>
      <PartnerProvider>
        <div className={isFluid ? 'container-fluid' : 'container'}>
          <NavbarVertical />
          <div className="content">
            <NavbarTop />
            <Switch>
              <Route path="/" exact component={Dashboard} />
              <DashboardRoutes />
            </Switch>
            <Footer />
          </div>
        </div>
      </PartnerProvider>
    </CategoriesProvider>
  ) : null;
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
