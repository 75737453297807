import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { APIURL } from '../config';
import PropTypes from 'prop-types';
import i18n from "../i18n";

const CategoriesContext = createContext({
  categories: [],
  category: null,
  isLoading: false,
  setCategories: () => {},
  setCategory: () => {},
});

const CategoriesProvider = ({ children }) => {
  const [category, setCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  /**
   * Retrieve up to date information from API
   * @returns {Promise<void>}
   */
  const fetchData = async () => {
    const locale = i18n.language || 'en';

    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${APIURL}/partner/categories`, {
        headers: {
          Authorization: `Bearer ${token}`,
          locale: locale,
        },
      });

      setCategories(response.data.categories);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   * Fetch all Jobs when provider gets called
   */
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', fetchData);

    return () => {
      i18n.off('languageChanged', fetchData);
    };
  }, [fetchData]);

  const value = {
    categories,
    category,
    fetchData,
    isLoading,
    setCategories,
    setCategory,
  };

  return <CategoriesContext.Provider value={value}>{children}</CategoriesContext.Provider>;
};

CategoriesProvider.defaultProps = {
  children: null,
};

CategoriesProvider.propTypes = {
  children: PropTypes.element,
};

export default CategoriesContext;
export { CategoriesProvider };
