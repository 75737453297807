import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoginService from '../../../services/login';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import i18n from 'i18next';

const FacebookSignInButton = ({ signUp, requestCompleted }) => {
  const { t } = useTranslation('translations');
  const history = useHistory();

  /**
   * Process Facebook response and check for user existence
   * @param tokenData
   * @returns {Promise<void|*>}
   */
  const validateFacebookLogin = async tokenData => {
    const { accessToken, email } = tokenData;

    const {
      data: { result },
    } = await LoginService.checkFacebookToken(accessToken, email);

    if (signUp) {
      // Account exists and phone verification done
      if (result) {
        const { data } = await LoginService.loginWithFacebook(accessToken, { skipSession: true });

        // Set language for the portal based on user settings
        const {
          userDetails: { language },
        } = data;
        if (language) await i18n.changeLanguage(language);

        return requestCompleted(data);
      } else {
        return requestCompleted({ userDetails: { ...tokenData, facebookToken: tokenData.accessToken } });
      }
    } else {
      // Account exists and phone verification done
      if (result) {
        const { data } = await LoginService.loginWithFacebook(accessToken);

        // Set language for the portal based on user settings
        const {
          userDetails: { language },
        } = data;
        if (language) await i18n.changeLanguage(language);

        if (data.partner) {
          return history.push('/');
        } else {
          toast.warn(t('auth.login.partnerMustBeCreatedFirst'));
          return history.push('/onboarding');
        }
      } else {
        toast.warn(t('auth.login.createAccountFirst'));
        return history.push('/onboarding');
      }
    }
  };

  return (
    <FacebookLogin
      appId="266933974226360"
      callback={validateFacebookLogin}
      fields="name,email,picture"
      render={renderProps => (
        <Button onClick={renderProps.onClick} outline={true} color="facebook" className="w-100" size="sm">
          <FontAwesomeIcon icon={faFacebook} className="mr-2" />
          <span>{t('auth.login.signWithFacebook')}</span>
        </Button>
      )}
    />
  );
};

FacebookSignInButton.defaultProps = {
  signUp: false,
  requestCompleted: () => {},
};

FacebookSignInButton.propTypes = {
  signUp: PropTypes.bool,
  requestCompleted: PropTypes.func,
};

export default FacebookSignInButton;
