import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Button, Spinner } from "reactstrap";
import { toast } from 'react-toastify';
import { APIURL } from "../../config";
import corner2 from '../../assets/img/illustrations/corner-2.png';
import Background from "../common/Background";
import PartnerContext from "../../context/PartnerContext";

const StripeConnection = () => {
  const { t } = useTranslation('translations');
  const { partner } = useContext(PartnerContext);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Calls to API to prepare redirect URL to navigate to Stripe
   * @returns {Promise<void>}
   */
  const startStripeConnection = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${APIURL}/partner/stripe`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      if (response.status === 200) {
        window.location = response.data;
      } else {
        toast.warn(t('dashboard.stripeConnection.error'));
        setIsLoading(false);
      }
    } catch (err) {
      toast.warn(t('dashboard.stripeConnection.error'));
      setIsLoading(false);
    }
  }

  return partner && !partner.stripeAccountId ? (
    <div className="card-deck">
      <div className="mb-3 card">
        <Background image={corner2} className="bg-card" />
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <h5 className="fs-0">{t('dashboard.stripeConnection.subtitle')}</h5>
              <h3 className="mb-0">{t('dashboard.stripeConnection.title')}</h3>
              <p className="mt-2">{t('dashboard.stripeConnection.content')}</p>
              <Button className="col-12 col-sm-6 col-md-4" onClick={startStripeConnection} color="primary" block={true} disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner size="sm" className="mr-3" />
                    {t('dashboard.stripeConnection.buttonActive')}
                  </>
                ) : t('dashboard.stripeConnection.button') }
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default StripeConnection;
