import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import AuthService from '../../services/AuthService';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const ForgetPasswordForm = () => {
  const { t } = useTranslation('translations');
  const [emailSent, setEmailSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth.forgotPassword.emailMustValid'))
      .required(t('auth.forgotPassword.fieldRequired')),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    getValues,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  /**
   * Send reset email
   * @returns {Promise<void>}
   */
  const sendResetEmail = async () => {
    if (emailSent) return;

    const emailValid = await trigger('email');
    if (!emailValid) return;

    const email = getValues('email');

    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 15 * 1000);

    const response = await AuthService.requestPasswordReset(email);

    if (!response) {
      toast.error(t('auth.forgotPassword.resetErrorToast'));
      return;
    }

    if (response.status === 204) {
      setEmailSent(true);
    } else {
      toast.error(response.data?.details);
    }
  };

  return (
    <Fragment>
      {!emailSent ? (
        <Row className="mb-3">
          <div>
            <h5 className="mb-3">{t('auth.forgotPassword.title')}</h5>
            <span>{t('auth.forgotPassword.description')}</span>
          </div>
        </Row>
      ) : null}

      {!emailSent ? (
        <Form className="mt-4" onSubmit={handleSubmit(sendResetEmail)}>
          <Row form className="mb-3">
            <Col>
              <FormGroup>
                <input
                  name="email"
                  id="email"
                  defaultValue={null}
                  type="text"
                  disabled={isDisabled}
                  placeholder={t('auth.forgotPassword.emailPlaceholder')}
                  className={classNames({ 'border-danger': errors && errors.email?.message, 'form-control': true })}
                  {...register('email')}
                />
              </FormGroup>
              {errors.email && <span className="text-danger">{errors.email.message}</span>}
            </Col>
          </Row>

          <FormGroup>
            <Button color="primary" block type="submit" disabled={isDisabled}>
              {t('auth.forgotPassword.sendInstructions')}
            </Button>
          </FormGroup>
        </Form>
      ) : null}

      {emailSent ? (
        <Row className="mb-3">
          <div>
            <h5 className="mb-3">{t('auth.forgotPassword.titleSent')}</h5>
            <span>{t('auth.forgotPassword.descriptionSent')}</span>
          </div>
        </Row>
      ) : null}

      <Link className="fs--1 text-600" to="/auth/login">
        <span className="d-inline-block mr-1">&larr;</span>
        {t('auth.forgotPassword.backToLoginLink')}
      </Link>
    </Fragment>
  );
};

export default ForgetPasswordForm;
