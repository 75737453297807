import React, {createContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import usePartnerFetch from "../hooks/usePartnerFetch";

const PartnerContext = createContext({
  partner: null,
  linkedAccounts: [],
  fetchData: () => {},
  setLinkedAccounts: () => [],
  setPartner: () => {},
});

const PartnerProvider = ({ children }) => {
  const [partner, setPartner] = useState(null);
  const [linkedAccounts, setLinkedAccounts] = useState([]);

  const { data, fetchData } = usePartnerFetch();

  useEffect(() => {
    if (data.data) {
      data.data.flows && data.data.flows.map((flow) => {
        if (flow.minimumPrice !== undefined)
          flow.minimumPrice = flow.minimumPrice / 100;
      })
      setPartner(data.data);
      setLinkedAccounts(data.linkedAccounts);
    }
  }, [data]);

  const value = {
    fetchData,
    linkedAccounts,
    partner,
    setPartner,
    setLinkedAccounts,
  };

  return <PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>;
};

PartnerProvider.defaultProps = {
  children: null,
};

PartnerProvider.propTypes = {
  children: PropTypes.element,
};

export default PartnerContext;
export { PartnerProvider };
